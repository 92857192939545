import React from 'react'
import styled from 'styled-components'
import {Row,SecTitle} from './'
import {Map} from '../components/Images';
import { injectIntl, Link } from 'gatsby-plugin-intl';
import {Media} from "../media/media"
import {phone, mail, adres} from '../assets/icons'; 


const Ctc = styled.section`
text-align:center;
display:flex;
flex-direction: column;
background: #FFF;
margin-top:50px;
font-size: 22px;

.contactTxt{
    justify-content: space-evenly
    min-width:50%;
    margin:50px auto;
    div{
      padding:1em 1.5em;

      ${Media.sm`
      padding:0.5em 0.5em;
      `}
    }

    ${Media.sm`
      margin:1em auto;
      flex-direction: column ;
  `}
}
.google_maps{
    width: 100%;
    height:50%
    .gatsby-image-wrapper div{
            padding-bottom:50% !important;
        }
    }
}
`

const H1 = styled.h1 `
  font-weight: 400;
  font-size: 46px;
  color: black;
  display: inline-block;
  width: fit-content;
  padding:0.2em;
  margin: auto;
  text-transform: Capitalize;
`

const SubTitle = styled.p `
  font-family:var(--serif);
  text-transform:Capitalize;
  color:var(--main-color);
  text-align: center;
  line-height: 1.5;
`

const TextCtn = styled.p `
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.5;
  color: var(--dark-color-light);

  ${Media.sm`
    margin: 0;
  `}
`;

const Contact = ({ intl }) => (
    <Ctc id="contact">
        <SecTitle>{intl.formatMessage({ id: 'home.hero.contact' })}</SecTitle>
        <Row className="contactTxt">
          <div>
            <div style={{ display:"flex", flexDirection:"row",alignItems: "center",justifyContent: "center" }} >
              <img src={adres} style={{ height:"25px", marginRight:"8px" }}/>
              <SubTitle>{intl.formatMessage({ id: 'contact.adres_t' })}</SubTitle>
            </div>
            <TextCtn style={{fontWeight:"600", marginBottom:"0.5em" }}>{intl.formatMessage({ id: 'contact.title' })}</TextCtn>
            <TextCtn>{intl.formatMessage({ id: 'contact.adres' })}</TextCtn>
          </div>
          <div>
            <div style={{ display:"flex", flexDirection:"row", alignItems: "center", justifyContent: "center" }} >
              <img src={phone} style={{ height:"25px",  marginRight:"8px" }}/>
              <SubTitle>{intl.formatMessage({ id: 'contact.call' })}</SubTitle>
            </div>
            <TextCtn style={{ marginBottom:"0.5em" }}> {intl.formatMessage({ id: 'contact.phones' })}</TextCtn>
          </div>
          <div>
            <div style={{ display:"flex", flexDirection:"row", alignItems: "center", justifyContent: "center" }} >
              <img src={mail} style={{ height:"25px",  marginRight:"8px" }}/>
              <SubTitle>{intl.formatMessage({ id: 'contact.write_t' })}</SubTitle>
            </div>
            <TextCtn>{intl.formatMessage({ id: 'contact.mail' })}</TextCtn>
          </div>
        </Row>
        <a className="google_maps" target="_blank" href="https://goo.gl/maps/7Xau9RPBADjph4eK8"><Map/></a>
    </Ctc>
)

export default injectIntl(Contact);