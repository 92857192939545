import React from 'react';
import { graphql } from 'gatsby';
import { injectIntl, Link } from 'gatsby-plugin-intl';

import { Button, Heading, Section,SectionRow, SecTitle, Image, Layout_srv, SEO, Text } from '../components';
import {UrszulaBW} from '../components/Images';
// import {SEO, Row, SecTitle, SectionRow, Button, AboutMe, Hero, Layout, Section, Services, Service} from '../components';
import  Contact  from '../components/contact';

const About = ({ intl, data }) => {
  return (
    <Layout_srv>
      <SEO title={intl.formatMessage({ id: 'about.title' })} />
      <Section className="aboutHero">
        <SecTitle>{intl.formatMessage({ id: 'about.title' })}</SecTitle>
        <SectionRow className='aboutHeroRow' >
          <div className='aboutHeroInner' >
            <h1>{intl.formatMessage({ id: 'about.attorney' })}</h1>
            <br/>
            <p>{intl.formatMessage({ id: 'home.text2' })}</p>
          </div>
          <UrszulaBW/>
        </SectionRow>
      </Section>
      <Contact/>
    </Layout_srv>
  );
};

export default injectIntl(About);

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "image1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
