import React from "react"
import {
    StaticQuery,
    graphql
} from "gatsby"
import Img from "gatsby-image"

function imageData(WrappedComponent) {
    return props => ( <StaticQuery query = {graphql `
        query Imgquery{
            heroPhoto: file(relativePath:{eq:"hero.png"}){
                childImageSharp{
                    fluid(maxWidth:1920){
                        ...GatsbyImageSharpFluid
                    }
                }
            },
            urszulaPhoto: file(relativePath:{eq:"Urszula_kolor.png"}){
                childImageSharp{
                    fluid(maxWidth:500){
                        ...GatsbyImageSharpFluid
                    }
                }
            },

            urszulaBWPhoto: file(relativePath:{eq:"UrszulaBW.png"}){
                childImageSharp{
                    fluid(maxWidth:500){
                        ...GatsbyImageSharpFluid
                    }
                }
            },
            bussinesPhoto: file(relativePath:{eq:"business.png"}){
                childImageSharp{
                    fluid(maxWidth:300){
                        ...GatsbyImageSharpFluid
                    }
                }
            },
            pclientsPhoto: file(relativePath:{eq:"private.png"}){
                childImageSharp{
                    fluid(maxWidth:300){
                        ...GatsbyImageSharpFluid
                    }
                }
            },
            bussinesWide: file(relativePath:{eq:"Photo-8.png"}){
                childImageSharp{
                    fluid(maxWidth:1920){
                        ...GatsbyImageSharpFluid
                    }
                }
            },
            pclientsWide: file(relativePath:{eq:"private.png"}){
                childImageSharp{
                    fluid(maxWidth:1920){
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            mapPhoto: file(relativePath:{eq:"Photo-9.png"}){
                childImageSharp{
                    fluid(maxWidth:1920){
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            mapPicture: file(relativePath:{eq:"Map.png"}){
                childImageSharp{
                    fluid(maxWidth:1920){
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `}
    
    render = {
        data => <WrappedComponent {...props} imageData = {data} />
    }
    />)     
}

const Heroimg = imageData(props => (
    <Img fluid={props.imageData.heroPhoto.childImageSharp.fluid }/>
    ))

const UrszulaK = imageData(props => (
    <Img fluid={props.imageData.urszulaPhoto.childImageSharp.fluid }/>
    ))

const UrszulaBW = imageData(props => (
    <Img fluid={props.imageData.urszulaBWPhoto.childImageSharp.fluid }/>
    ))

const Bussines = imageData(props => (
    <Img fluid={props.imageData.bussinesPhoto.childImageSharp.fluid }/>
    ))

const Pclients = imageData(props => (
    <Img fluid={props.imageData.pclientsPhoto.childImageSharp.fluid }/>
    ))

const WBussines = imageData(props => (
    <Img fluid={props.imageData.bussinesWide.childImageSharp.fluid }/>
    ))

const Wpclients = imageData(props => (
    <Img fluid={props.imageData.pclientsWide.childImageSharp.fluid }/>
    ))
const Mapa = imageData(props => (
    <Img fluid={props.imageData.mapPhoto.childImageSharp.fluid }/>
    ))
const Map = imageData(props => (
    <Img fluid={props.imageData.mapPicture.childImageSharp.fluid }/>
    ))


export {
    Heroimg,
    UrszulaK,
    UrszulaBW,
    Bussines,
    Pclients,
    WBussines,
    Wpclients,
    Mapa,
    Map
};
